import React, { useState, useEffect, useContext, useRef } from 'react';
import {
  IonModal,
  IonContent,
  IonButton,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonTitle,
  IonIcon,
  IonSegment,
  IonLabel,
  IonSegmentButton,
  IonList,
  IonItem,
  IonAvatar,
} from '@ionic/react';
import '../../../styles/home/notificationModal.scss';
import { closeOutline } from 'ionicons/icons';
import { MessageRepository, Client, ChannelRepository } from '@amityco/ts-sdk';
import { useHistory } from 'react-router-dom';
import { allUsers } from '../../../global/request/user';
import { AuthContext } from '../../authentication/AuthContext';
import FoodFightIcon from '../../../assets/foodfight_red_primary_05.png';
import { login } from '../../../services/amityClient';
import { RecentChatItemLoader } from '../../skeletalLoaders/SkeletalLoaders';
import generateUniqueKey from '../../../UniqueKeyGenerator/UniqueKeyGenerator';
// Mockup Data
import { generateTestNotifications } from './MockupData';

const RenderList = ({ title, items, onItemClick }) => (
  <div>
    {items.length > 0 && (
      <>
        <h2 className="time-header">{title}</h2>
        <IonList lines="none">
          {items.map(item => (
            <IonItem key={item.id} className="msg-item" button onClick={() => onItemClick(item.channelId, item.sender)}>
              <IonAvatar slot="start" className="msg-avatar">
                <img src={FoodFightIcon} alt={`${item.sender}'s avatar`} />
              </IonAvatar>
              <IonLabel>
                <h3 className="msg-header">{item.sender}</h3>
                <p className="msg-content">{item.message}</p>
                <p className="msg-time">{item.time}</p>
              </IonLabel>
            </IonItem>
          ))}
        </IonList>
      </>
    )}
  </div>
);

const HomepageNotificationModal = ({ isOpen, onClose }) => {
  const [modalAnimationClass, setModalAnimationClass] = useState('not-modal-slide-in');
  const [selectedSegment, setSelectedSegment] = useState('notifications');
  const authCtx = useContext(AuthContext);
  const { idData } = useContext(AuthContext);
  const [channels, setChannels] = useState([]);
  const [messages, setMessages] = useState([]);
  const [channelMembers, setChannelMembers] = useState({});
  const [allUsersData, setAllUsersData] = useState([]);
  const [isLoadingUsers, setIsLoadingUsers] = useState(true);
  const messageSubscriptionRef = useRef(null);
  const liveCollectionRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const history = useHistory();

  const ensureLogin = async () => {
    if (authCtx.accessData && authCtx.accessData.sub && authCtx.tokens.idToken) {
      try {
        await login(authCtx.accessData.sub, authCtx.idData.name, authCtx.tokens.idToken);
      } catch (error) {
        console.error('Login failed in Notification Center:', error);
      }
    }
  };

  const classifyTime = dateString => {
    const today = new Date();
    const itemDate = new Date(dateString);

    const timeDifference = today - itemDate; // Difference in milliseconds
    const oneDay = 24 * 60 * 60 * 1000; // Milliseconds in one day
    const oneWeek = 7 * oneDay;

    if (timeDifference < oneDay) {
      return 'today';
    }
    if (timeDifference < oneWeek) {
      return 'lastWeek';
    }
    return 'earlier';
  };

  const organizeByTime = (data, suffix) => {
    const timeCategories = {
      today: [],
      lastWeek: [],
      earlier: [],
    };

    Object.values(data).forEach(item => {
      timeCategories[classifyTime(item.classifyTime)].push(item);
    });

    Object.keys(timeCategories).forEach(key => {
      timeCategories[key].sort((a, b) => new Date(b.time) - new Date(a.time));
    });

    return Object.fromEntries(Object.entries(timeCategories).map(([key, items]) => [`${key}${suffix}`, items]));
  };

  const notificationExamples = generateTestNotifications();
  const [chatExamples, setChats] = useState([]);
  const { todayNot, lastWeekNot, earlierNot } = organizeByTime(notificationExamples, 'Not');
  const [todayChat, setTodayChat] = useState([]);
  const [lastWeekChat, setLastWeekChat] = useState([]);
  const [earlierChat, setEarlierChat] = useState([]);

  useEffect(() => {
    if (isOpen) {
      setModalAnimationClass('not-modal-slide-in');
    } else {
      setModalAnimationClass('not-modal-slide-out');
    }
  }, [isOpen]);

  const ensureClientConnected = async () => {
    if (!Client.isConnected()) {
      try {
        await Client.startSession({ userId: authCtx.userData.id, displayName: authCtx.userData.name });
      } catch (error) {
        console.error('Client connection failed:', error);
      }
    }
  };

  const cleanAndSetChannels = newChannels => {
    setChannels(prevChannels => {
      const combinedChannels = [...prevChannels, ...newChannels];
      const uniqueChannels = combinedChannels.reduce((acc, channel) => {
        if (!acc.some(existingChannel => existingChannel.channelId === channel.channelId)) {
          acc.push(channel);
        }
        return acc;
      }, []);

      return uniqueChannels;
    });
  };

  const fetchChannels = async (after = null) => {
    ChannelRepository.getChannels(
      {
        membership: 'member',
        sortBy: 'lastCreated',
        limit: 100,
        after,
      },
      ({ data, error, pagination }) => {
        if (error) {
          console.error('Error fetching channels:', error);
          return;
        }
        if (data) {
          cleanAndSetChannels(data);

          if (pagination?.hasNextPage) {
            fetchChannels(pagination.next);
          }
        }
      },
    );
  };

  useEffect(() => {
    const fetchNotificationData = async () => {
      await ensureLogin();
      fetchChannels();
    };

    if (isOpen) {
      fetchNotificationData();
    }
  }, [isOpen, authCtx]);

  const fetchMessages = async subChannelId => {
    if (messageSubscriptionRef.current === subChannelId) {
      return;
    }

    if (messageSubscriptionRef.current) {
      messageSubscriptionRef.current();
    }

    await ensureClientConnected();

    const liveCollection = MessageRepository.getMessages(
      {
        subChannelId,
        limit: 50,
        reverse: true,
      },
      ({ data: fetchedMessages, error }) => {
        if (error) {
          console.error('Error fetching messages:', error);
          return;
        }

        if (fetchedMessages) {
          const unreadMessages = fetchedMessages.filter(message => message.readCount === 0);
          const friendMessages = unreadMessages.filter(message => message.creatorId !== idData.sub);
          const sortedMessages = friendMessages.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
          setMessages(prevMessages => ({
            ...prevMessages,
            [subChannelId]: sortedMessages,
          }));
        }
      },
    );

    liveCollectionRef.current = liveCollection;
  };

  const fetchChannelMembers = async channelId => {
    try {
      const result = await new Promise((resolve, reject) => {
        ChannelRepository.Membership.getMembers(
          { channelId, memberships: ['member'], sortBy: 'lastCreated' },
          ({ data: members, error }) => {
            if (error) {
              console.error(`Error fetching members for channel ${channelId}:`, error);
              reject(new Error(`Error fetching members for channel ${channelId}`));
            } else {
              const userIds = members?.map(member => member.userId) || [];
              resolve(userIds);
            }
          },
        );
      });
      return result;
    } catch (err) {
      console.error(`Unexpected error fetching members for channel ${channelId}:`, err);
      return [];
    }
  };

  useEffect(() => {
    const fetchMembersForChannels = async () => {
      const memberPromises = channels.map(async channel => {
        const userIds = await fetchChannelMembers(channel.channelId);
        return { channelId: channel.channelId, userIds };
      });

      const members = await Promise.all(memberPromises);

      const memberMap = members.reduce((acc, { channelId, userIds }) => {
        acc[channelId] = userIds;
        return acc;
      }, {});

      setChannelMembers(memberMap);
    };

    const fetchAllUsers = async () => {
      try {
        const response = await allUsers(authCtx.tokens.idToken);
        setAllUsersData(response);
        setIsLoadingUsers(false);
      } catch (error) {
        console.error('Error fetching all users:', error);
        setIsLoadingUsers(false);
      }
    };

    if (channels.length > 0) {
      fetchMembersForChannels();
      fetchAllUsers();
      setLoading(false);
    }
  }, [channels]);

  useEffect(() => {
    const recieveMessages = async () => {
      // Fetch messages for each channel
      // eslint-disable-next-line no-underscore-dangle
      const messagePromises = channels.map(channel => fetchMessages(channel._id));
      await Promise.all(messagePromises);
    };

    if (channels.length > 0) {
      recieveMessages();
    }
  }, [channelMembers]);

  useEffect(() => {
    console.log('chat examples', chatExamples);
  }, [chatExamples]);

  const getUserNameById = userId => {
    if (isLoadingUsers) {
      return 'Loading...';
    }
    const foundUser = allUsersData.find(userRecord => userRecord.id === userId);
    return foundUser ? foundUser.name || 'Unknown User' : 'Unknown User';
  };

  useEffect(() => {
    const formatTime = dateString => {
      const date = new Date(dateString);
      const now = new Date();

      const isToday = now.toDateString() === date.toDateString();
      const isYesterday = new Date(now.setDate(now.getDate() - 1)).toDateString() === date.toDateString();

      if (isToday) {
        return new Intl.DateTimeFormat('en-US', {
          hour: 'numeric',
          minute: 'numeric',
          hour12: true,
        }).format(date);
      }
      if (isYesterday) {
        return `Yesterday, ${new Intl.DateTimeFormat('en-US', {
          hour: 'numeric',
          minute: 'numeric',
          hour12: true,
        }).format(date)}`;
      }
      return new Intl.DateTimeFormat('en-US', {
        month: 'numeric',
        day: 'numeric',
        year: 'numeric',
      }).format(date);
    };

    const formatClassifyTime = dateString => {
      const date = new Date(dateString);
      return new Intl.DateTimeFormat('en-US', {
        month: 'numeric',
        day: 'numeric',
        year: 'numeric',
      }).format(date);
    };

    const formatMessages = () => {
      return Object.entries(messages).reduce((formattedMessages, [subChannelId, messageArray]) => {
        const userIds = channelMembers[subChannelId] || [];
        const filteredUserIds = userIds.filter(id => id !== authCtx?.idData?.sub);
        const userDisplayNames = filteredUserIds.map(userId => getUserNameById(userId)).join(', ');

        messageArray.forEach(message => {
          formattedMessages[message.messageId] = {
            id: message.messageId,
            channelId: message.channelId,
            sender: userDisplayNames,
            message: message.data.text,
            time: formatTime(message.createdAt),
            classifyTime: formatClassifyTime(message.createdAt),
          };
        });

        return formattedMessages;
      }, {});
    };

    const chats = formatMessages();
    console.log('chats are', chats);
    setChats(chats);
    const { todayChats, lastWeekChats, earlierChats } = organizeByTime(chatExamples, 'Chats');
    setTodayChat(todayChats);
    setLastWeekChat(lastWeekChats);
    setEarlierChat(earlierChats);
  }, [messages]);

  const handleItemClick = (channelId, friendName) => {
    history.push({
      pathname: `/chat/${channelId}`,
      state: { friendName },
    });
  };

  return (
    <IonModal isOpen={isOpen} onDidDismiss={onClose} className={`notification-modal ${modalAnimationClass}`}>
      <IonHeader>
        <IonToolbar className="toolbar">
          <IonTitle className="ion-no-padding">
            <div className="ion-text-wrap">Notification Center</div>
          </IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={onClose}>
              <IonIcon icon={closeOutline} slot="icon-only" />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="segments">
          <IonSegment
            mode="md"
            className="notification-segment"
            value={selectedSegment}
            onIonChange={e => setSelectedSegment(e.detail.value)}
          >
            <IonSegmentButton value="notifications" className="segment-btn">
              <IonLabel>Notifications</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="chat" className="segment-btn">
              <IonLabel>Chat</IonLabel>
            </IonSegmentButton>
          </IonSegment>
        </div>

        {/* Conditionally render either the skeleton loader or the content */}
        {loading && (
          <div>
            <h2 className="loader-time-header">Today</h2>
            {Array.from({ length: 3 }).map(() => (
              <RecentChatItemLoader key={generateUniqueKey()} />
            ))}
            <h2 className="loader-time-header">Last Week</h2>
            {Array.from({ length: 3 }).map(() => (
              <RecentChatItemLoader key={generateUniqueKey()} />
            ))}
            <h2 className="loader-time-header">Earlier</h2>
            {Array.from({ length: 3 }).map(() => (
              <RecentChatItemLoader key={generateUniqueKey()} />
            ))}
          </div>
        )}

        {!loading && (
          <div className="tab-content">
            {selectedSegment === 'notifications' && (
              <>
                <RenderList title="Today" items={todayNot} onItemClick={() => {}} />
                <RenderList title="Last Week" items={lastWeekNot} onItemClick={() => {}} />
                <RenderList title="Earlier" items={earlierNot} onItemClick={() => {}} />
              </>
            )}

            {selectedSegment === 'chat' && (
              <>
                <RenderList title="Today" items={todayChat} onItemClick={handleItemClick} />
                <RenderList title="Last Week" items={lastWeekChat} onItemClick={handleItemClick} />
                <RenderList title="Earlier" items={earlierChat} onItemClick={handleItemClick} />
              </>
            )}
          </div>
        )}
      </IonContent>
    </IonModal>
  );
};

export default HomepageNotificationModal;
