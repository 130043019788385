import React, { useState, useEffect, useContext, useMemo } from 'react';
import { IonList, IonItem, IonLabel, IonIcon, IonButton } from '@ionic/react';
import { arrowForwardOutline } from 'ionicons/icons';
import notificationBell from '../../assets/notification-bell.svg';
import Header from '../header/Header';
import { ProductTourContext } from '../../global/ProductTourContext';
import HomepageNotificationModal from '../home/homepageNotifications/HomepageNotificationModal';

const FriendSearchComponent = ({ users, handleUserClick }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredUsers, setFilteredUsers] = useState([]);
  const { searchBarRef } = useContext(ProductTourContext);
  const [showModal, setShowModal] = useState(false);

  const filterUsers = query => {
    if (!query) {
      setFilteredUsers([]);
      return;
    }
    const lowercasedQuery = query.toLowerCase();
    const filtered = users.filter(
      user =>
        user.name.toLowerCase().includes(lowercasedQuery) || user.username.toLowerCase().includes(lowercasedQuery),
    );
    setFilteredUsers(filtered);
  };

  const debouncedFilterUsers = useMemo(() => {
    let timer;
    return query => {
      clearTimeout(timer);
      timer = setTimeout(() => filterUsers(query), 100);
    };
  }, [users]);

  const handleClick = userId => {
    setSearchQuery('');
    handleUserClick(userId);
  };

  const handleNotification = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const headerButtons = [
    <IonButton className="notification " key="Notification" onClick={() => handleNotification()}>
      <IonIcon icon={notificationBell} />
    </IonButton>,
  ];

  useEffect(() => {
    debouncedFilterUsers(searchQuery);
  }, [searchQuery, users]);

  return (
    <>
      <Header
        forwardRef={searchBarRef}
        searchTerm={searchQuery}
        setSearchTerm={setSearchQuery}
        placeholder="Search opponents..."
        debounce={100}
        btns={headerButtons}
      >
        {searchQuery && (
          <IonList className="search-list" key={searchQuery}>
            {filteredUsers.map(user => (
              <IonItem key={user.id} lines="full" onClick={() => handleClick(user.id)}>
                <div className="search-user" style={{ width: '100%' }}>
                  <IonLabel>
                    {user.name} - {user.username}
                  </IonLabel>
                  <IonIcon icon={arrowForwardOutline} slot="end" />
                </div>
              </IonItem>
            ))}
          </IonList>
        )}
      </Header>
      <HomepageNotificationModal isOpen={showModal} onClose={closeModal} />
    </>
  );
};

export default FriendSearchComponent;
